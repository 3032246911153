import Tabs from "../../layout/tabs";

import 'chart.js/auto';
import { ArrowLeftCircleIcon, ArrowLeftOnRectangleIcon, BuildingLibraryIcon, DocumentTextIcon, LifebuoyIcon, LockClosedIcon, UserCircleIcon, UserIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { PersonaContext } from "../login/context";
import { ProfileContext } from "./context";

export default function ListBankAccount() {
    const navigate = useNavigate()
    const persona = useContext(PersonaContext)
    const profile = useContext(ProfileContext)

    const handleNew = () => {
        navigate("/settings/bank-account/create")
    }


    if (profile.loading) {
        return <div></div>
    }

    return <div className="flex flex-col h-full">
        <div>
            <div className="relative h-32 text-center hg-bg-blue" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
                <ArrowLeftCircleIcon className="fixed mt-2 ml-2 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={() => navigate("/settings")}/>
                <h1 className="pt-12 text-xl text-white pl-3 pr-6">Bank accounts</h1>
            </div>
        </div>

        <div className="flex flex-col justify-between p-5 h-full">
            {profile.bankAccounts?.length === 0 && <div className="">
                <h1 className="text-center text-lg text-blue-950 font-medium">Here where you can manage your bank accounts for income distributions and redemption requests.</h1>
            </div>}

            {profile?.bankAccounts?.length > 0 && <ul>
                {profile?.bankAccounts?.map(b => <li key={b.accountNumber} className="flex flex-col gap-3 border-b bg-white pt-3 pb-3 text-sm font-medium">
                    <div className="flex justify-between">
                        <div className="flex flex-col gap-1">
                            <span className="text-blue-950 font-light">{b.accountName}</span>
                            <span className="text-blue-950 font-light">{b.accountNumber}</span>
                            <span className="text-blue-950 font-light">{b.bankName}</span>
                        </div>
                        {/* {b.status === "verified" && <span className="text-green-500 font-normal capitalize">{b.status}</span>}
                        {b.status === "unverified" && <span className="text-amber-500 font-normal capitalize">{b.status}</span>} */}
                    </div>
                    {/* {b.status === "unverified" && <span className="text-xs font-normal text-amber-700 border border-amber-700 bg-amber-100 p-3 rounded-xl">
                        Redemption to this bank account is subject to verification.
                    </span>} */}
                </li>)}
            </ul>}

            {persona.canInvest && <button type="submit" onClick={handleNew} className="w-full mt-10 p-3 hg-bg-blue text-white rounded-full">
                Create bank account
            </button>}
        </div>

    </div>
}
