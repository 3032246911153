import { ArrowLeftCircleIcon, BoltIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import {DebounceInput} from 'react-debounce-input';
import React, { useEffect, useState, MouseEvent, ChangeEvent, useContext} from "react";
import { Error, GetSubscriptionFeeOutput, getSubscriptionFee, invest, updateInvestorCategory } from "../../api";
import { Loader } from "../../component";
import Bitcoin from "../../asset/bitcoin";
import MalaysianRinggit from "../../asset/malaysian-ringgit";
import { FundListContext } from "../fund/context";
import { ProfileContext } from "../settings/context";
import { PortfolioContext } from "../portfolio/context";
import { PersonaContext } from "../login/context";
import Ethereum from "../../asset/ethereum";
import { AxiosError, AxiosResponse } from "axios";
import Titans from "../../asset/titans";
import moment from "moment";
import Hourglass from "../../asset/hourglass";


interface InvestState {
    step: number

    fundId?: string
    fundClassSequence?: string
    fundClassCount?: number
    amount?: number
}

export default function Invest() {
    const [state, setState] = useState<InvestState>({
        step: 1,
    })
    const persona = useContext(PersonaContext)
    const profile = useContext(ProfileContext)
    const fundList = useContext(FundListContext)
    
    const navigate = useNavigate()

    const { fundId, fundClassSequence } = useParams()

    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        if (!persona.canInvest) {
            navigate("/portfolio")
            return
        }

        // fund and class already provided in URL, render amount step.
        if (fundId && fundClassSequence && fundList.funds.length > 1) {
            setState(prev => ({
                ...prev,
                fundId: fundId,
                fundClassSequence: fundClassSequence,
                step: 3,
            }))
        }
    },[])
    
    const handleSelectFund = (ev: MouseEvent<HTMLLIElement>) => {
        let stepDelta = 1
        const {fundid, fundclasssequence} = ev.currentTarget.dataset
        const selectedFund = fundList.funds.filter(f => f.id === fundid)[0]
        if (fundclasssequence && parseInt(fundclasssequence) > 0) {
            stepDelta++
            // For a fund with single class, we verify the risk score to direct at this stage to SAT. (e.g Bitcoin Fund)
            if (!profile.client?.riskScore || profile.client?.riskScore < selectedFund?.riskScore) {
                navigate(`/invest/suitability-assessment/fund/${fundid}/class/${fundclasssequence}`)
                return
            }
        }
        setState(prev=> ({
            ...prev,
            fundId: fundid,
            fundClassSequence: fundclasssequence,
            fundClassCount: fundList.funds.filter(f => f.id === fundid)[0].classes.length,
            step: prev.step + stepDelta,
        }))
    }
    const handleSelectClass = (ev: MouseEvent<HTMLLIElement>) => {
        const {fundclasssequence} = ev.currentTarget.dataset
        const selectedFund = fundList.funds.filter(f => f.id === state.fundId)[0]
        if (!profile.client?.riskScore || profile.client?.riskScore < selectedFund?.riskScore) {
            navigate(`/invest/suitability-assessment/fund/${state.fundId}/class/${fundclasssequence}`)
            return
        }
        setState(prev=> ({
            ...prev,
            fundClassSequence: fundclasssequence,
            step: prev.step + 1,
        }))
    }
    const handlePreviewClick = (ev: MouseEvent) => {
        if (!state.amount) {
            return
        }
        setState(prev=> ({
            ...prev,
            step: prev.step + 1,
        }))
    }
    const handleAmountChange = (amount: number) => {
        setState(prev => ({
            ...prev,
            amount: amount
        }))
    }
    
    const handleBack = () => {
        if (state.step > 1) {
            if (state.step === 3 && (state.fundClassCount === 1)) {
                setState(prev => ({ ...prev, step: 1, amount: 0 }))
                return
            }
            if (state.step === 3) {
                setState(prev => ({ ...prev, step: prev.step - 1, amount: 0 }))
                return
            }
            setState(prev => ({ ...prev, step: prev.step - 1 }))
            return
        }
        navigate("/portfolio")
    }

    if (profile.loading) return <></>
    if (profile.client.status !== "active") {
        return <NonActiveClientView handleBack={handleBack} />
    }
    if(!fundList.privateMandates && profile.client.investorCategory === "retailInvestor") {
        return <RetailInvestorView handleBack={handleBack} clientId={persona.clientId} accountId={persona.accountId}/>
    }

    switch (state.step) {
        case 1:
            return <Step1 handleBack={handleBack} handleSelectFund={handleSelectFund} />
        case 2:
            if (!state.fundId) {
                setState(prev => ({...prev, step: prev.step - 1}))
                return <></>
            }
            return <Step2 handleBack={handleBack} handleSelectClass={handleSelectClass} fundId={state.fundId} />
        case 3:
            if (!state.fundId || !state.fundClassSequence) {
                setState(prev => ({...prev, step: prev.step - 1}))
                return <></>
            }
            return <Step3 handleBack={handleBack} handlePreviewClick={handlePreviewClick} handleAmountChange={handleAmountChange} fundId={state.fundId} fundClassSequence={state.fundClassSequence}/>
        case 4:
            if (!state.fundId || !state.fundClassSequence || !state.amount) {
                setState(prev => ({...prev, step: prev.step - 1}))
                return <></>
            }
            if (!persona.clientId || !persona.accountId) {
                navigate("/invest")
                return <></>
            }
            return <Step4 handleBack={handleBack} clientId={persona.clientId} accountId={persona.accountId} fundId={state.fundId} fundClassSequence={state.fundClassSequence} amount={state.amount} />
        default:
            navigate("/portfolio")
            return <></>
    }
}

interface Step1Props {
    handleBack: (ev: MouseEvent) => void
    handleSelectFund: (ev: MouseEvent<HTMLLIElement>) => void
}

const Step1 = (props: Step1Props) => {
    const fundList = useContext(FundListContext)
    const {handleBack, handleSelectFund} = props

    const renderFundIcon = (fundCode: string) => {
        if (fundCode === "hsbtcf") {
            return <Bitcoin className="w-6 -rotate-12" color="white" />
        }
        if (fundCode === "hsethf") {
            return <Ethereum className="w-6" color="white" />
        }
        if (fundCode === "hsrif") {
            return <MalaysianRinggit className="w-6" color="white" />
        }
        if (fundCode === "hsctf") {
            return <Titans className="w-6" color="white" />
        }
        return <BoltIcon className="w-6 -rotate-12" color="white" />
    }

    return <div className="pb-24">
        <div className="relative">
            <ArrowLeftCircleIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={handleBack}/>
            <h1 className="ml-8 pt-20 text-xl hg-text-blue pl-3 pr-6">What would you like to invest?</h1>
        </div>
        <ul className="mt-12 text-white flex flex-wrap justify-around gap-5 text-center">
            {!fundList.loading && fundList.funds?.map(f => {
                const fundClassSequnce = f.classes.length === 1 ? 1 : 0
                return <li key={f.id} className="border text-white text-sm cursor-pointer w-72 hg-bg-light-blue rounded-lg" data-fundid={f.id} data-fundclasssequence={fundClassSequnce} onClick={handleSelectFund}>
                    <span className="block hg-bg-blue w-9 h-8 p-1 font-medium rounded-br-3xl rounded-tl-lg">
                        {renderFundIcon(f.code)}
                    </span>
                    <span className="block text-sm pt-3 pb-8 pr-5 pl-5 hg-text-blue font-medium">
                        {f.name}
                        <span className="block mt-1 text-xs text-blue-400">{f.tagline}</span>
                    </span>
                </li>
            })}
        </ul>
    </div>
}

interface Step2Props {
    fundId: string
    handleBack: (ev: MouseEvent) => void
    handleSelectClass: (ev: MouseEvent<HTMLLIElement>) => void
}

const Step2 = (props: Step2Props) => {
    const {handleBack, handleSelectClass} = props
    const fundList = useContext(FundListContext)
    const targetFund = fundList.funds.filter(f => f.id === props.fundId)[0]
    return <div className="pb-24">
            <div className="relative">
                <ArrowLeftCircleIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={handleBack}/>
                <h1 className="ml-8 pt-20 text-xl hg-text-blue pl-3 pr-6">You have selected <span className="font-medium">{targetFund.name}</span></h1>
            </div>
            <ul className="mt-12 text-white flex flex-wrap justify-around gap-5 text-center">
                {targetFund.classes.map((c) => {
                    return <li className="border text-white text-sm cursor-pointer w-72 hg-bg-light-blue" onClick={handleSelectClass} data-fundclasssequence={c.sequence}>
                        <span className="block hg-bg-blue w-9 h-8 p-1 font-medium rounded-br-3xl">
                            <MalaysianRinggit className="w-6" color="white" />
                        </span>
                        <span className="block text-sm pt-3 pb-8 pr-5 pl-5 hg-text-blue font-medium">
                            Class {c.label}
                            <span className="block mt-1 text-xs text-blue-400">{c.canDistribute ? "Returns distributed in a form of fund's units or cash" : "Returns accumulated" }</span>
                        </span>
                    </li>
                })}
            </ul>
        </div>
}

interface Step3Props {
    fundId: string
    fundClassSequence: string
    handleBack: (ev: MouseEvent) => void
    handleAmountChange: (amount: number) => void
    handlePreviewClick: (ev: MouseEvent) => void
}

const Step3 = (props: Step3Props) => {
    const [amount, setAmount] = useState(0)
    const fundList = useContext(FundListContext)
    const portfolioContext = useContext(PortfolioContext)
    const targetFund = fundList.funds.filter(f => f.id === props.fundId)[0]
    const targetClass = targetFund.classes.filter(c => c.label === targetFund.classes[parseInt(props.fundClassSequence) - 1].label)[0]
    const profile = useContext(ProfileContext)

    let minimumInvestmentAmount = targetClass.limits.minimumInitialInvestment
    let investments = portfolioContext.breakdown?.filter(b => b.fundCode === targetFund.code)
    if (investments && investments.length > 0) {
        minimumInvestmentAmount = targetClass.limits.minimumAdditionalInvestment
    }
    const maximumInvestmentAmount = 10000000

    const handleAmountChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const amount = parseFloat(ev.currentTarget.value || "0")
        setAmount(amount)
        props.handleAmountChange(amount)
    }

    return <div>
        <ArrowLeftCircleIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={props.handleBack}/>
        <div className="ml-11 flex flex-col gap-6">
            <h1 className="pt-20 text-xl hg-text-blue pr-6">You have selected <span className="font-medium">{targetFund?.name} - Class {targetFund?.classes[parseInt(props.fundClassSequence) - 1]?.label}</span></h1>
            
            <div className="flex flex-col gap-2">
                <span className="block text-blue-900 text-lg font-medium">Investment amount</span>
                <div className="relative w-full">
                    <span className="absolute bottom-3 left-4 text-gray-700">RM</span>
                    <input type="number" inputMode="numeric" onChange={handleAmountChange} defaultValue={amount <= 0 ? "" : amount.toPrecision()} className="w-5/6 border p-3 pl-12 rounded-full" placeholder="10,000.00"/>
                </div>
                {amount < minimumInvestmentAmount && <span className="ml-4 text-xs text-red-700 font-medium">Minimum investment is {minimumInvestmentAmount?.toLocaleString("en-my", { currency: "myr", style: "currency", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                {amount > maximumInvestmentAmount && <div className="flex ml-4 gap-1">
                <span className="text-xs text-red-700 font-medium">
                    Maximum investment using the Wallet is {maximumInvestmentAmount?.toLocaleString("en-my", { currency: "myr", style: "currency", minimumFractionDigits: 2, maximumFractionDigits: 2 })}.
                </span>
                {/* <span className="underline text-red-700 font-medium text-xs" onClick={}>Contact us for more info.</span> */}
                </div>}
            </div>
            
            <button onClick={props.handlePreviewClick} disabled={!amount || amount < minimumInvestmentAmount || amount > 10000000} className="w-5/6 mt-20 block p-3 hg-bg-blue text-white border shadow-2xl rounded-full disabled:bg-blue-300">
                Preview
            </button>
        </div>
    </div>
}

interface Step4Props {
    clientId: string
    accountId: string
    fundId: string
    fundClassSequence: string
    
    amount: number
    
    handleBack: (ev: MouseEvent) => void
}

interface Step4State {
    stepLoading: boolean
    salesChargePercentage: number
    fundSubscriptionFee: number
    postFeeAmount: number
    discountPercentage: number

    voucherCode: string
    
    investLoading: boolean
    investFailed: boolean
}

const Step4 = (props: Step4Props) => {
    const [ state, setState ] = useState<Step4State>({
        stepLoading: true,

        salesChargePercentage: 0,
        fundSubscriptionFee: 0,
        postFeeAmount: 0,
        discountPercentage: 0,

        voucherCode: "",

        investLoading: false,
        investFailed: false,
    })

    const portfolio = useContext(PortfolioContext)
    const fundList = useContext(FundListContext)
    const targetFund = fundList.funds.filter(f => f.id === props.fundId)[0]
    useEffect(() => {
        setState(prev => ({...prev, investLoading: true}))
        getSubscriptionFee({
            clientId: props.clientId,
            accountId: props.accountId,
            fundId: props.fundId,
            fundClassSequence: parseInt(props.fundClassSequence),
            amount: props.amount,
            voucherCode: state.voucherCode,
        }).then(resp => {
            const output = resp.data as GetSubscriptionFeeOutput
            setState(prev => ({
                ...prev,
                investLoading: false,
                stepLoading: false,
                salesChargePercentage: output.clientSubscriptionFee,
                fundSubscriptionFee: output.fundSubscriptionFee,
                postFeeAmount: output.postFeeAmount,
                discountPercentage: output.voucherDiscountPercentage,
                voucherCode: output.voucherCodeUsed,
            }))
        }).catch((err: AxiosError<Error>) => {
            setState(prev => ({
                ...prev,
                investLoading: false,
                voucherCode: "",
            }))
        })
    }, [state.voucherCode])
    const navigate = useNavigate()

    const handleVoucherChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const voucherCode = ev.target.value 
        setState(prev=> ({
            ...prev,
            voucherCode,
        }))
    }

    const handleInvest = (ev: MouseEvent) => {
        setState(prev => ({...prev, investLoading: true}))
        invest({
            source: "wallet",
            type: "purchase",
            clientId: props.clientId,
            accountId: props.accountId,
            fundId: props.fundId,
            fundClassSequence: parseInt(props.fundClassSequence),
            amount: props.amount,
            voucherCode: state.voucherCode,
        }).then(resp => {
            portfolio.reloadAccountRequests()
            navigate(`/portfolio/request/${resp.data?.request?.id}`)
        }).catch(() => {
            setState(prev => ({
                ...prev,
                investLoading: false,
                investFailed: true
            }))
        })
    }

    const renderInvestButton = () => {
        if (state.investLoading) {
            return <button disabled={state.investLoading} className="hg-bg-blue mt-12 w-full p-3 text-white border shadow-2xl rounded-full disabled:bg-blue-300">
                <Loader classNames="w-7 h-7 text-blue-300" />
            </button>
        }
        return <DebounceInput onChange={() => {}} type="button" value="Invest" onClick={handleInvest} debounceTimeout={500} className="hg-bg-blue mt-12 w-full p-3 text-white border shadow-2xl rounded-full disabled:bg-blue-300 outline-0 cursor-pointer" />
        // return <button onClick={handleInvest} className="hg-bg-blue mt-12 w-full p-3 text-white border shadow-2xl rounded-full disabled:bg-blue-300 outline-0">
        //     Invest
        // </button>
    }
    
    const renderSalesCharge = () => {
        if (state.salesChargePercentage === state.fundSubscriptionFee) {
            return <div className="grid grid-cols-5">
                <span className="col-span-2 text-blue-900 font-medium">Sales charge %</span>
                <span className="col-span-3">
                    {state.salesChargePercentage?.toLocaleString("en-my", {maximumFractionDigits: 2, minimumFractionDigits: 2})}%
                </span>
            </div>
        }
        return <div className="grid grid-cols-5">
            <span className="col-span-2 text-blue-900 font-medium">Sales charge %</span>
            <div className="col-span-3 flex flex-col gap-1">
                <div className="flex gap-3">
                    <span className="text-gray-500 line-through">{state.fundSubscriptionFee?.toLocaleString("en-my", {maximumFractionDigits: 2, minimumFractionDigits: 2})}%</span>
                    <span className="text-green-500">{state.salesChargePercentage?.toLocaleString("en-my", {maximumFractionDigits: 2, minimumFractionDigits: 2})}%</span>
                </div>
                <span className="w-fit text-xs font-medium bg-green-200 text-green-800 px-2 py-1 rounded-full">{state.discountPercentage}% waived!</span>
            </div>
        </div>
    }

    return <div>
        <ArrowLeftCircleIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={props.handleBack}/>
        <div className="mx-8 flex flex-col gap-6">
            <h1 className="pt-20 text-xl hg-text-blue">Preview of investment</h1>
            {state.stepLoading && <div className="flex justify-center"><Loader classNames="w-12 h-12 text-blue-100" /></div>}
            {!state.stepLoading && <React.Fragment>
                <div className="grid grid-cols-5">
                    <span className="col-span-2 text-blue-900 font-medium ">Fund</span>
                    <span className="col-span-3">{targetFund.name}</span>
                </div>
                <div className="grid grid-cols-5">
                    <span className="col-span-2 text-blue-900 font-medium ">Class</span>
                    <span className="col-span-3">{targetFund.classes[parseInt(props.fundClassSequence) - 1]?.label}</span>
                </div>
                <div className="grid grid-cols-5">
                    <span className="col-span-2 text-blue-900 font-medium ">Gross amount</span>
                    <span className="col-span-3">{props.amount?.toLocaleString("en-my", {currency: "myr", style:"currency", maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
                </div>
                {renderSalesCharge()}
                {state.salesChargePercentage > 0 && <div className="grid grid-cols-5">
                    <span className="col-span-2 text-blue-900 font-medium">Voucher</span>
                    <DebounceInput type="text" value={state.voucherCode} debounceTimeout={800} className={`px-0 w-fit col-span-3 border-0 border-b-2 border-dashed uppercase focus:outline-none focus:ring-0 ${state.salesChargePercentage < state.fundSubscriptionFee ? "border-b-green-500 focus:border-b-green-500 text-green-500": ""}`} placeholder="VOUCHER10" onChange={handleVoucherChange}/>
                </div>}
                <div className="grid grid-cols-5">
                    <span className="col-span-2 text-blue-900 font-medium ">Nett amount</span>
                    <span className="col-span-3">{state.postFeeAmount?.toLocaleString("en-my", {currency: "myr", style:"currency", maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
                </div>
                {renderInvestButton()}
                {state.investFailed && state.voucherCode !== "" && <div className="mt-5 w-full p-3 border border-red-700 bg-red-100 text-sm rounded-full text-center">
                    Voucher code is invalid.
                </div>}
                {state.investFailed && state.voucherCode === "" && <div className="mt-5 w-full p-3 border border-red-700 bg-red-100 text-sm rounded-full text-center">
                    An error ocurred, please try again.
                </div>}
            </React.Fragment>
            }
        </div>
    </div>
}

interface RetailInvestorViewProps {
    clientId: string
    accountId: string
    handleBack: (ev: MouseEvent) => void
}

interface RetailInvestorViewState {
    loading: boolean
    failed: boolean
}

const RetailInvestorView = (props: RetailInvestorViewProps) => {
    const [ state, setState ] = useState<RetailInvestorViewState>({
        loading: false,
        failed: false,
    })
    const navigate = useNavigate()
    
    const [selected, setSelected] = useState<"accreditedInvestor" | "highNetworthInvestor" | "sophisticatedInvestor250k" | "retailInvestor">("retailInvestor")
    const handleSelect = (ev: MouseEvent<HTMLLIElement>) => {
        const value = ev.currentTarget.dataset.value
        if (!value) return
        if (value !== "accreditedInvestor" && value !== "highNetworthInvestor" && value !== "sophisticatedInvestor250k" && value !== "retailInvestor") return
        setSelected(value)
    }

    const handleSubmit = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }))
        updateInvestorCategory({
            clientId: props.clientId,
            accountId: props.accountId,
            investorCategory: selected,
        }).then(() => {
            window.location.reload()
        }).catch(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                failed: true
            }))
        })
    }

    const investorCategoryOptions = [
        {
            label: "Accredited investor",
            value: "accreditedInvestor",
        },
        {
            label: "High net-worth investor",
            value: "highNetworthInvestor",
        },
        {
            label: "Sophisticated investor - RM 250k",
            value: "sophisticatedInvestor250k",
        },
        {
            label: "Retail investor",
            value: "retailInvestor",
        },
    ]
    
    return <div>
        <ArrowLeftCircleIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={props.handleBack}/>
        <div className="mx-6 pb-6 flex flex-col gap-6">
            <h1 className="pt-16 text-xl hg-text-blue">Only Accredited, High Net Worth Individuals (HNWI) or Sophisticated Investors are eligible to invest into regulated wholesale funds. Please choose the category below that applies to you before proceeding.</h1>
            <ul className="w-full flex flex-col gap-5">
                {investorCategoryOptions.map((o) => {
                    if (o.value === "retailInvestor") return <React.Fragment key={o.value}></React.Fragment>
                    return <li key={o.value} onClick={handleSelect} data-value={o.value} className={`font-medium p-4 rounded-3xl cursor-pointer border border-blue-800 ${selected !== o.value ? "bg-blue-50 text-blue-800" : "bg-blue-800 text-white"}`}>{o.label}</li>
                })}
            </ul>
            <div className="flex flex-col w-full gap-3 bg-gray-50 border border-gray-300 p-3 rounded-3xl">
                <div>
                    <h3 className="text-sm font-semibold text-gray-700">Accredited investor</h3>
                    <ul className="text-sm">
                        <li>Licensed (CMSRL) or Registered persons, including CEOs/Directors of CMSLs</li>
                    </ul>
                </div>
                <div>
                    <h3 className="text-sm font-semibold text-gray-700">High net-worth investor</h3>
                    <ul className="text-sm">
                        <li>Annual Income of {">"}RM300,000 (Individual)</li>
                        <li>or {">"}RM400,000 (Households) </li>
                        <li>or {">"}RM1,000,000 investment portfolio</li>
                        <li>or {">"}RM3,000,000 net personal assets.</li>
                    </ul>
                </div>
                <div>
                    <h3 className="text-sm font-semibold text-gray-700">Sophisticated investor - RM 250k</h3>
                    <ul className="text-sm">
                        <li>Any investor who can invest RM250k or more for each transaction.</li>
                    </ul>
                </div>
            </div>
            {selected !== "retailInvestor" && <button onClick={handleSubmit} disabled={state.loading} className="hg-bg-blue w-full p-3 text-white border rounded-full outline-0 disabled:bg-blue-300">
                {state.loading ? <Loader classNames="w-7 h-7 text-blue-300" /> : `I am ${investorCategoryOptions.filter(o => selected === o.value)[0]?.label}`}
            </button>}
            {state.failed && <div className="w-full p-3 border border-red-700 bg-red-100 text-sm rounded-full text-center">
                An error ocurred, please try again.
            </div>}
        </div>
    </div>
}

interface NonActiveClientViewProps {
    handleBack: (ev: MouseEvent) => void
}

const NonActiveClientView = (props: NonActiveClientViewProps) => {
    return <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal md:gap-20 h-full">
            <div className="flex flex-col items-center text-center w-full gap-6">
                <Hourglass />
                <h3></h3>
                <h3 className="text-blue-800 font-medium text-xl">
                    Your account is now under review. Please keep an eye on your email inbox; we'll be in touch within 1-2 business days.
                </h3>
            </div>
            <a onClick={props.handleBack} className="cursor-pointer text-center p-3 hg-bg-blue text-white rounded-full m-10">Home</a>
        </div>
}
